import React from 'react';
import {
  HoverDotContainer, Text, HoverDotMain, Description,
} from './styles';

const HoverDot = ({
  clickDot, insideText, positionLeft, positionTop, description, hiddenDot,
}) => (
  <>
    <HoverDotContainer
      onClick={clickDot}
      role="button"
      tabindex="0"
      aria-label={insideText}
      positionLeft={positionLeft}
      positionTop={positionTop}
    >
      <Text>{insideText}</Text>
      {description && <Description>{description}</Description>}
      {!hiddenDot && <HoverDotMain label={insideText} />}
    </HoverDotContainer>
  </>
);
export default HoverDot;
