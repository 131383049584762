import styled, { css, keyframes } from 'styled-components';
import { fonts, colors } from '../../../../../globals';

export const MainWrapper = styled.div`
  position: relative;
  height: 100vh;
`

export const ContentWrapper = styled.div`
  padding-top: 8vw;
  display: inline-block;
  
`

const opacityAnim = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;


export const OpacityWrapper = styled.div`
  opacity: 0;
  animation: ${opacityAnim} 1.5s forwards;
  p {
    font-size: 0.9rem;
    @media (max-width: 1700px) {
      font-size: 0.8rem;
      max-width: 27vw;
  }
  }
  
`;

export const Date = styled.h3`
  ${{ ...fonts.headline_1 }}
  ${{ ...colors.light }}
  font-size: 5rem;
  opacity: 0.9;
  transition: all .5s;
  @media (max-width: 1700px) {
    font-size: 4rem;

  }
`
export const Dot = styled.div`
  width: 1.875rem;
  height: 1.875rem;
  border: 0.45rem solid #ff0000;
  border-radius: 50%;
  background-color: #f3f2f0;
  transition: 0.45s;
`

export const DotWrapper = styled.div`
  width: 1.875rem;
  height: 1.875rem;
  margin: 0 auto 1rem;
  &:hover ${Dot} {
    border: 0.8rem solid #ff0000;
    transform: scale(0.47);
  }
`



export const Button = styled.div`
  position: absolute;
  ${({ top, right }) => `
        top: ${top}%;
        right: ${right}%;
    `}
  &:hover {
    cursor: pointer;
    ${Date} {
      opacity: 1;
    }
    /* ${Dot} {
      border: 0.8rem solid #ff0000;
      transform: scale(0.47);
    } */
  }
`

export const ImageContainer = styled.div`
  width: 100vw;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
`

export const Image = styled.img`
  width: 100%;
  height: 100%;
`

export const LogoWrapper = styled.div`
  display: flex;
  align-items: center;
  position: absolute;
  bottom: 7vh;
  left: 9vw;
`

export const LogoImg = styled.img`
  width: 8.5rem;
  margin: 0 1.5rem;

  &:hover {
    cursor: pointer;
  }
`