import React from 'react';
import BackgroundAnimation from 'src/components/mobile/backgroundAnimation/backgroundAnimation';
import TextAnimation from 'src/components/mobile/textAnimation/textAnimation';
import mp4 from 'src/assets/videos/chapter_5/sub_3/5-3-1-mobile.mp4';
import webm from 'src/assets/videos/chapter_5/sub_3/5-3-1-mobile.webm';
import { BlockContainer, ChpaterTitle } from '../../_styles';
import SubSlider from './subSlider';

const Slide = ({ query, title }) => (
  <BlockContainer height="1550px">
    <BackgroundAnimation
      src={webm}
      mp4={mp4}
    >
      <ChpaterTitle marginTop="6rem">{title}</ChpaterTitle>
      <TextAnimation
        containerStyle={{ height: '15%', marginTop: '22rem', marginBottom: '-3rem' }}
        body={query.header.body}
      />
      <TextAnimation
        containerStyle={{ height: '10%' }}
        body={query.mdx.body}
      />
      <SubSlider query={query} />
    </BackgroundAnimation>
  </BlockContainer>
);

export default Slide;
