import React from 'react';
import SliderContent from 'src/components/mobile/sliderContent/sliderContent';
import HoverDot from 'src/components/mobile/hoverDot/hoverDot';
import { BlockContainer } from '../../_styles';

const SubSlider = ({ style, query }) => (
  <BlockContainer style={{ padding: '4rem 0', ...style }}>
    <SliderContent>
      {query.hoverDot.exports.hoverInfo.map((dot, i) => <HoverDot key={i} alternative hiddenDot insideText={dot.insideText} description={dot.description} />)}
    </SliderContent>
  </BlockContainer>
);

export default SubSlider;
