import React, { useState, useCallback } from 'react';
import { ContentWrapper, OpacityWrapper, Button, Date, Dot, LogoWrapper, LogoImg, DotWrapper } from './styles'
import LeftColContent from '../leftColContent/leftColContent';
import { ParallaxMordo, Mordo } from 'src/components/desktop/parallax/ParallaxMordo.jsx';
import BackgroundAnimation from 'src/components/desktop/backgroundAnimation/backgroundAnimation';

import Video from 'src/assets/videos/chapter_5/sub_3/clock.webm';
import VideoMP4 from 'src/assets/videos/chapter_5/sub_3/clock.mp4';

import logoPR from 'src/assets/images/_shared/logo-pr-white.svg';
import logoNP from 'src/assets/images/_shared/logo-niepodlegla-white.svg';


const DateButton = React.memo(({ date, top, right, handler, data }) => {
  return (
    <Button onClick={() => handler(data)} top={top} right={right}>
      <DotWrapper>
        <Dot />
      </DotWrapper>
      <Date tabIndex={0}>
        {date}
      </Date>
    </Button>
  )
})



const Content = ({ query, contentIndex }) => {
  const data = {
    0: query.main_content.body,
    1: query.year_1989.body,
    2: query.year_1945.body,
    3: query.year_1939.body,
    4: query.year_1920.body,
  }
  return (
    <ContentWrapper>
      <LeftColContent body={query.main_header.body} />
      <OpacityWrapper key={contentIndex}>
        <LeftColContent body={data[contentIndex]} />
      </OpacityWrapper>
    </ContentWrapper>
  )
}

const InteractiveContent = ({ query, assetQuery }) => {
  const [contentIndex, setContentIndex] = useState(0);

  const choiceHandler = useCallback((ix) => {
    setContentIndex(ix)
  }, [setContentIndex])

  return (
    <ParallaxMordo >
      <Mordo
        mainscene
        move={{ x: -20, y: -10 }}
        background={{ color: 'rgba(19,35,46, 0.25)' }}
      >
        <BackgroundAnimation src={Video} mp4={VideoMP4} responsive />
      </Mordo>

      <Mordo
        scene={1}
        move={{ x: 5, y: 5 }}
        customStyle={{ marginLeft: '-2vw' }}
      >
        <Content query={query} contentIndex={contentIndex} />
      </Mordo>
      <Mordo
        scene={2}
        move={{ x: -5, y: -5 }}
      >
        <DateButton date='1989' top={3} right={14} handler={choiceHandler} data={1} />
        <DateButton date='1945' top={14} right={37} handler={choiceHandler} data={2} />
        <DateButton date='1939' top={38} right={48} handler={choiceHandler} data={3} />
        <DateButton date='1920' top={67} right={53} handler={choiceHandler} data={4} />
      </Mordo>
      <Mordo
        scene={3}
        move={{ x: -10, y: -5 }}
      >
        <LogoWrapper>
          <a href="https://niepodlegla.gov.pl/" target="_blank" tabIndex="0">
            <LogoImg src={logoNP} alt="Niepodlegla" />
          </a>
          <a href="https://www.polskieradio.pl/" target="_blank" tabIndex="0">
            <LogoImg src={logoPR} alt="Polskie Radio" />
          </a>
        </LogoWrapper>
      </Mordo>
    </ParallaxMordo>
  );
};

export default InteractiveContent;
