import React from 'react';
import Slide1 from './slide1';
import { Wrapper } from '../../../_templates/_styles';
import NextChapter from '../../../../../components/mobile/nextChapter/nextChapter';

const WrapperMobile = () => (
  <Wrapper>
    <Slide1 />
    <NextChapter preTitle="Autorzy" />
  </Wrapper>
);

export default WrapperMobile;
