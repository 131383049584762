import React from 'react';
import MainSlider from 'src/components/desktop/mainSlider/mainSlider.jsx';

import Slide1 from 'src/slides/desktop/pl/chapter_5/sub_3/slide1';

import WrapperMobile from 'src/slides/mobile/pl/chapter_5/sub_3/wrapperMobile';

import { isMobile } from 'react-device-detect';
import SEO from '../../../components/_shared/seo';

// desktop

const allSlides = [
  <Slide1 />,
];

const exceptions = [];

const Desktop = () => (
  <>
    <SEO title="Wpływ Bitwy Warszawskiej na dzieje świata | Bitwa Warszawska" description="Nad Wisłą Polacy zatrzymali zegar historii na 20 lat." lang="pl" />
    <MainSlider slides={allSlides} exceptions={exceptions} />
  </>
);

// mobile


const Mobile = () => (
  <>
    <SEO title="Zatrzymany zegar historii" lang="pl" />
    <WrapperMobile />
  </>
);

// export

const Page = () => {
  if (isMobile) return <Mobile />;
  return <Desktop />;
};

export default Page;
