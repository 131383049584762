import styled, { keyframes } from 'styled-components';

export const HoverDotContainer = styled.div`
  width: ${({ positionLeft }) => !positionLeft && '100%'};
  height: ${({ positionLeft }) => !positionLeft && '100%'};
  margin-left: -1.5rem;
  position: ${({ positionLeft }) => positionLeft && 'absolute'};
  overflow-wrap: normal;
  ${({ positionLeft }) => positionLeft && `
    left: ${positionLeft};
 `}
  ${({ positionTop }) => positionTop && `
     top: ${positionTop};
  `}
  z-index: 10;
`;

const showDots = keyframes`
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
`;

export const HoverDotMain = styled.div.attrs((label) => ({ tabIndex: 0, ariaLabel: label }))`
  width: 1.875rem;
  height: 1.875rem;
  border: 0.45rem solid #ff0000;
  border-radius: 50%;
  background-color: #f3f2f0;
  z-index: 10;
  transition: 0.45s;
  cursor: pointer;
  animation: ${showDots} 0.5s cubic-bezier(0.820, 0.000, 0.195, 1.000);
`;


export const Text = styled.p`
  color: #f9f8f3;
  font-family: "Abril Fatface";
  font-size: 1.875rem;
  font-weight: 400;
  margin-bottom: 1rem;
  padding-right: 1rem;
`;

export const Description = styled.p`
  color: #fbfbfb;
  font-family: "Red Hat Display";
  font-size: 0.875rem;
  font-weight: 400;
  width: 90%;
  padding-bottom: 0.5rem;
`;

export const HoverDotHoverContainer = styled.div`
  width: 1.875rem;
  height: 1.875rem;
  position: absolute;
  z-index: 11;
  cursor: pointer;
  &:hover ${HoverDotMain} {
    border: 0.8rem solid #ff0000;
    transform: scale(0.47);
  }
  &:hover~div{
    opacity: 1;
  }
`;
