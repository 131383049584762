import React from 'react';
import InteractiveContent from 'src/components/desktop/interactiveContent/interactiveContent';
import { useStaticQuery, graphql } from 'gatsby';
import { SlideLarge } from '../../_styles';
// import BackgroundAnimation from '../../../../components/desktop/backgroundAnimation/backgroundAnimation.jsx';

const Slide = ({ query }) => {
  const assetQuery = useStaticQuery(graphql`
  query {
    file(relativePath: { eq: "chapter_5/sub_3/5-3-1.png"}) {
      childImageSharp {
          fixed(width: 1920) {
            ...GatsbyImageSharpFixed_withWebp_noBase64
          }
        }
    }
  }
`);
  return (
    <SlideLarge>
      <InteractiveContent query={query} assetQuery={assetQuery} />
    </SlideLarge>
  );
};


export default Slide;
